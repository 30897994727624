<template>
  <div>
    <b-modal
        v-if="isHaveAccess"
        v-model="showLocal"
        scrollable centered hide-footer no-close-on-backdrop
        dialog-class="obhodnyPripad-form"
        @hidden="$emit('close-modal')"
    >
      <template #modal-title>
        <div class="text-center">
          <h6 class="text-nowrap w-100">{{ localId ? `Obchodní případ č. ${formData.kodZakazky}` : 'Nový obchodní případ' }}</h6>
        </div>
      </template>

      <b-overlay :show="isLoading" rounded="sm">
        <div class="modalWindow-w100" @click.stop>
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
              <div class="" :class="{ 'd-flex': !localId }">
                <b-form-group id="kodZakazky-group" class="label-1 pr-1" :class="[localId ? 'w-50' : 'w-100']">
                  <label for="kodZakazky" class="pr-1">Kód případu</label>
                  <label class="req">*</label>
                  <ValidationProvider :rules="getRulesKodZakazky" name="'Kód případu'" v-slot="{ classes, errors }">
                    <b-form-input
                        id="kodZakazky"
                        name="kodZakazky"
                        v-model="formData.kodZakazky"
                        v-debounce="validateKodZakazkyOnExist"
                        class="form-control form-control-sm"
                        :disabled="!isCanEditKodZakazky"
                        :title="errors[0]"
                        :state="getState(classes) && !isKodZakazkyExist"
                    />
                    <span class="errMsg fs-13px" v-html="getErrorKodPripadu(errors[0])"></span>
                    <div class="errMsg" v-if="isKodZakazkyExist">
                      Případ s takovým kódem již existuje
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <div class="d-flex w-100">
                  <b-form-group class="label-1" :class="[localId ? 'pr-1 w-50' : 'pl-2 w-100']">
                    <label for="obchodniZastupce" class="pr-1">Obchodní zástupce</label>
                    <label class="req">*</label>
                    <ValidationProvider :rules="{ required: true, mustBeInList: { list: lists.obchodniZastupce }}" name="'Obchodní zástupce'" mode="lazy" v-slot="{ classes, errors }">
                      <b-form-input
                          id="obchodniZastupce"
                          list="obchodniZastupceList"
                          name="obchodniZastupce"
                          class="form-control form-control-sm"
                          v-model.trim="formData.obchodniZastupceName"
                          :disabled="isSelectOzNameDisabled"
                          :title="errors[0]"
                          :state="getState(classes) && !validateWithDataFromList('obchodniZastupceName', null,'obchodniZastupce')"
                          @input="selectOzName"
                      />
                    </ValidationProvider>
                    <b-datalist
                        id="obchodniZastupceList"
                        :options="lists.obchodniZastupce"
                        autocomplete="off"
                    />
                  </b-form-group>

                  <b-form-group v-if="localId" class="label-1 w-50 pl-2">
                    <label for="managerKoordinator" class="pr-1">Projektový manažer / koordinátor</label>
                    <label class="req text-white">*</label>
                    <ValidationProvider :rules="{ mustBeInList: { list: lists.managerKoordinator }}" name="'Projektový manažer / koordinátor'" mode="lazy" v-slot="{ classes, errors }">
                      <b-form-input
                          id="managerKoordinator"
                          list="managerKoordinatorList"
                          name="managerKoordinator"
                          class="form-control form-control-sm"
                          v-model.trim="formData.managerKoordinatorName"
                          :disabled="isSelectManagerDisabled"
                          :title="errors[0]"
                          :state="getState(classes) && !validateWithDataFromList('managerKoordinatorName', null,'managerKoordinator', isCanBeEmpty = true)"
                      />
                    </ValidationProvider>
                    <b-datalist
                        id="managerKoordinatorList"
                        :options="lists.managerKoordinator"
                        autocomplete="off"
                    />
                  </b-form-group>
                </div>
              </div>

              <div class="d-flex">
                <b-form-group class="label-1 w-50 pr-1">
                  <label for="object" class="pr-1">Objekt</label>
                  <label class="req">*</label>
                  <ValidationProvider rules="required" name="'Objekt'" mode="lazy" v-slot="{ classes, errors }">
                    <b-form-select
                        id="object"
                        name="object"
                        v-model="formData.object"
                        :options="lists.object"
                        :title="errors[0]"
                        :state="getState(classes, formData.object)"
                        :disabled="formData.podepsana"
                        class="form-select form-select-sm"
                        size="sm"
                        @change="changeObject"
                    />
                  </ValidationProvider>
                </b-form-group>
                <b-form-group class="label-1 w-50 pl-2">
                  <label for="category" class="pr-1">Kategorie</label>
                  <label class="req ">*</label>
                  <ValidationProvider rules="required" name="'Kategorie'" mode="lazy" v-slot="{ classes, errors }">
                    <b-form-select
                        id="category"
                        name="category"
                        v-model="formData.category"
                        :options="lists.category"
                        :title="errors[0]"
                        :state="getState(classes, formData.category)"
                        :disabled="formData.podepsana"
                        class="form-select form-select-sm"
                        size="sm"
                        @change="changeCategory"
                    />
                  </ValidationProvider>
                </b-form-group>
              </div>

              <hr class="mt-3 mb-3">

              <div class="d-flex">
                <div class="label-1 w-50 pr-1">
                  <label for="label1" class="pr-1">{{label1[formData.object] || label1.familyHouse}}</label>
                  <label class="req">*</label>
                  <ValidationProvider rules="required|min:3" name="" v-slot="{ classes, errors }">
                    <b-form-input
                        id="label1"
                        name="label1"
                        v-model.trim="formData.clientLastName"
                        class="form-control form-control-sm"
                        :title="errors[0]"
                        :state="getState(classes)"
                        @change="validateExistujiciObhodnyPripad()"
                    />
                  </ValidationProvider>
                </div>
                <div class="label-1 pl-2 w-50">
                  <div class="d-flex">
                    <label for="clientName" v-html="label2[formData.object] || label2.familyHouse" class="font-weight-normal text-nowrap"></label>
                    <label class="req">*</label>
                  </div>

                  <ValidationProvider rules="required|min:1" name="'Jméno zákazníka'" v-slot="{ classes, errors }" class="d-flex">
                    <b-form-input
                        id="clientName"
                        name="clientName"
                        v-model.trim="formData.clientName"
                        class="form-control form-control-sm"
                        :title="errors[0]"
                        :state="getState(classes)"
                    />
                    <img v-if="isTypeFirmOrVS(formData.object)"
                         id="aresGovCzImg"
                         class="icon-logo ml-1 mt-1"
                         src="@/assets/ares_gov_cz.png"
                         alt="ares.gov.cz"
                         @click="openAresLink"
                         v-b-tooltip.hover="{
                          title: 'ARES',
                          delay: { show: 200, hide: 200 },
                          variant: 'secondary'
                        }"
                    />
                  </ValidationProvider>
                </div>
              </div>

              <div class="d-flex pt-2">
                <b-form-group class="label-1 w-50 pr-1" id="cityInstallation-group">
                  <label for="cityInstallation" class="pr-1">Město (obec) instalace</label>
                  <label class="req">*</label>
                  <ValidationProvider rules="required|min:3" name="'Město (obec) instalace'" v-slot="{ classes, errors }">
                    <b-form-input
                        id="cityInstallation"
                        name="cityInstallation"
                        v-model.trim="formData.cityInstallation"
                        class="form-control form-control-sm"
                        :title="errors[0]"
                        :state="getState(classes)"
                        @change="validateExistujiciObhodnyPripad()"
                    />
                  </ValidationProvider>
                </b-form-group>
                <b-form-group class="label-1 w-120px ml-2 pr-1" id="cityInstallation-group">
                  <label for="cityInstallation" class="pr-1">PSČ</label>
                  <label class="req">*</label>
                  <ValidationProvider rules="required|min:6|max:6" name="'PSČ'" v-slot="{ classes, errors }" class="d-flex">
                    <b-form-input
                        id="zip"
                        type="text"
                        size="sm"
                        v-model.trim="formData.zip"
                        v-mask="'### ##'"
                        @input="changeZIP"
                        :title="errors[0]"
                        :state="getState(classes)"
                    />
                    <img id="postaonlineImg" class="icon-logo ml-1 mt-1" src="@/assets/postaonline_cz.png" alt="Postaonline.cz" @click="openPostaLink" />
                    <b-tooltip target="postaonlineImg" variant="secondary">Vyhledávání PSČ</b-tooltip>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group class="label-1 w-180px ml-2">
                  <label for="kraj" class="pr-1">Kraj instalace</label>
                  <label class="req">*</label>
                  <ValidationProvider rules="required" name="'Kraj instalace'" mode="lazy" v-slot="{ classes, errors }">
                    <b-form-select
                        id="kraj"
                        name="kraj"
                        v-model="formData.kraj"
                        :options="lists.kraj"
                        class="form-select form-select-sm"
                        size="sm"
                        :title="errors[0]"
                        :state="getState(classes, formData.kraj)"
                    />
                  </ValidationProvider>
                </b-form-group>
              </div>

              <div v-if="!localId" class="d-flex pt-2">
                <b-form-group class="label-1 w-50 pr-1" id="phone-group">
                  <label for="phone" class="pr-1">Telefonní číslo</label>
                  <ValidationProvider :rules="formData.phone ? 'min:11' : ''" name="'Telefonní číslo'" v-slot="{ classes, errors }">
                    <b-form-input
                        id="phone"
                        type="tel"
                        size="sm"
                        v-model.trim="formData.phone"
                        v-mask="'### ### ###'"
                        :title="errors[0]"
                        :state="getState(classes)"
                    />
                  </ValidationProvider>
                </b-form-group>
                <b-form-group class="label-1 w-50 pl-2" id="email-group">
                  <label for="email" class="pr-1">Emailová adresa</label>
                  <ValidationProvider :rules="formData.email ? 'required|email' : ''" name="'Emailová adresa'" v-slot="{ classes, errors }">
                    <b-form-input
                        id="email"
                        type="email"
                        size="sm"
                        v-model.trim="formData.email"
                        :title="errors[0]"
                        :state="getState(classes)"
                    />
                  </ValidationProvider>
                </b-form-group>
              </div>

              <div v-if="isExistujiciObhodnyPripad" class="text-center pt-2">
                <a href="#" class="font-weight-bold" @click="showModalExistujiciObchodnyPripady">
                  Zkontrolovat existující obchodní případy
                </a>
              </div>
              <div v-else-if="isValidatedExisting" class="text-center pt-2">
                <span class="msg-notexist">Nebyly nalezeny žádné shody s existujícími případy</span>
              </div>

              <hr class="mt-3 mb-3">

              <div class="w-100 d-flex">
                <b-form-group class="label-1 w-35 pr-1">
                  <label for="contactSource" class="pr-1">Zdroj kontaktu</label>
                  <label class="req">*</label>
                  <ValidationProvider rules="required" name="'Zdroj kontaktu'" mode="lazy" v-slot="{ classes, errors }">
                    <b-form-select
                        id="contactSource"
                        name="contactSource"
                        v-model="formData.contactSource"
                        :options="lists.contactSource"
                        class="form-select form-select-sm"
                        size="sm"
                        :title="errors[0]"
                        :state="getState(classes, formData.contactSource)"
                    />
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="label-1 w-65 pl-2" id="noteContactSource-group">
                  <label for="noteContactSource" class="pr-1">Poznámka k zdroji kontaktu</label>
                  <label class="req text-white">*</label>
                  <ValidationProvider rules="max:50" name="'Poznámka k zdroji kontaktu'" v-slot="{ classes, errors }">
                    <b-form-input
                        id="noteContactSource"
                        name="noteContactSource"
                        v-model.trim="formData.noteContactSource"
                        class="form-control form-control-sm"
                        :title="errors[0]"
                        :state="getState(classes)"
                    />
                  </ValidationProvider>
                </b-form-group>
              </div>

              <div class="d-flex">
                <b-form-group class="label-1 w-50 pr-1" label="Zaevidováno" label-for="zaevidovanoDate">
                  <b-form-datepicker
                      id="zaevidovanoDate-datepicker"
                      v-model="formData.zaevidovanoDate"
                      :start-weekday="1"
                      size="sm"
                      locale="cs"
                      disabled
                  />
                </b-form-group>
                <b-form-group class="label-1 w-50 pl-2" label="Naposledy změněno" label-for="lastUpdatedAt">
                  <b-form-datepicker
                      id="lastUpdatedAt-datepicker"
                      v-model="formData.lastUpdatedAt"
                      :start-weekday="1"
                      size="sm"
                      locale="cs"
                      disabled
                  />
                </b-form-group>
              </div>

              <div v-if="localId" class="d-flex justify-content-end pt-1">
                <b-icon-symmetry-vertical @click="openCreateOpModal" class="cursor-pointer pt-3px" font-scale="1.4" />
                <span @click="openCreateOpModal" class="cursor-pointer pl-1">Vytvořit kopie OP</span>
              </div>

              <div class="d-flex justify-content-center w-100>">
                <b-button
                    type="submit"
                    class="mt-2 mb-2"
                    :class="[isSaveBtnEnabled ? '' : 'cursor-not-allowed']"
                    variant="primary"
                    :disabled="!isSaveBtnEnabled"
                >
                  {{ localId ? `Uložit změny` : 'Založit nový obchodní případ' }}
                </b-button>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </b-overlay>
    </b-modal>

    <b-modal id="create-copy-op-modal" centered hide-footer :title="'Vytvořit kopie č. ' + formData.kodZakazky" dialog-class="main-font">
      <p class="my-3 bold">Chcete vytvořit kopie OP?</p>
      <div class="d-flex justify-content-end pt-3">
        <b-button class="mr-3" variant="primary" @click="closeCreateCopyOpModal">Zrušit</b-button>
        <b-button variant="outline-danger" @click="createCopyOP">Ano</b-button>
      </div>
    </b-modal>

    <b-modal id="copy-op-done-modal" centered hide-footer title="Údaje z OP byly zkopírovány" dialog-class="main-font">
      <p class="my-3 bold">Údaje z OP byly zkopírovány. Před zavřením nezapomeňte tvar uložit.</p>
      <div class="d-flex justify-content-end pt-3">
        <b-button class="mr-3" variant="primary" @click="closeCopyOpDoneModal">Zrušit</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Axios from "axios";
import {PRIPAD_OBJECTS, PRIPAD_TYPES} from "@/constants/form.constants";
import _ from "lodash";
import {mapGetters} from "vuex";
import moment from "moment";
import commonMixin from "@/mixins/common.mixin.ts";
import { OBJECT_TYPE } from "@/constants/form.constants";
import axios from "axios";

const LABEL_1 = {
  govOrganization: 'Název subjektu',
  familyHouse: 'Příjmení zákazníka',
  apartmentBuilding: 'Ulice',
  firm: 'Název firmy',
}

const LABEL_2 = {
  govOrganization: '<b>Právní forma</b>',
  familyHouse: '<b>Jméno zákazníka</b>',
  apartmentBuilding: '<b>Číslo domu (čísla domů)</b>',
  firm: '<b>Právní forma</b> (s.r.o., a.s. nebo jiné)',
}

export default {
  computed: {
    ...mapGetters(['getUserNamesAndTitles', 'getUserNumberOZ', 'getUserFioTitles']),
    isHaveAccess() {
      return this.$store.getters.isObchodniPripadModalAllowed;
    },
    isStartValidation() {
      return this.formData.clientLastName && this.formData.cityInstallation;
    },
    isSaveBtnEnabled() {
      if (this.id || this.localId) return true;

      return !this.formSaving && (this.isNewObchodPripadFormCanBeSaved || (this.isValidatedExisting && !this.isExistujiciObhodnyPripad));
    },
    isCanEditKodZakazky() {
      return this.$store.getters.isOpravaKoduOP || !this.localId;
    },
    getRulesKodZakazky() {
      if (this.localId && this.isCanEditKodZakazky) {
        return 'required';
      }
      return 'required|kodZakazky8_10';
    },
    getNumberOz() {
      return this.$store.getters.userInfo.numberOZ;
    },
    isNoNumberOzOrCanSeeAllZakazky() {
      return !this.$store.getters.getUserNumberOZ || this.$store.getters.isCanSeeAllZakazky;
    },
    isSelectOzNameDisabled() {
      return this.localId && !this.isNoNumberOzOrCanSeeAllZakazky;
    },
    isSelectManagerDisabled() {
      return this.localId && !this.isNoNumberOzOrCanSeeAllZakazky;
    },
  },
  mixins: [commonMixin],
  props: {
    id: { type: String },
    kodZakazky: { type: String },
    show: { type: Boolean },
    isNewObchodPripadFormCanBeSaved: { type: Boolean, default: false },
  },
  data() {
    return {
      label1: LABEL_1,
      label2: LABEL_2,
      obchodniZastupce: [],
      obchodniZastupceFull: [],
      obchodniZastupceObjById: {},
      managerKoordinator: [],
      managerKoordinatorFull: [],
      managerKoordinatorObjById: {},
      localId: null,
      formData: {
        kodZakazky: '',
        clientName: '',
        clientLastName: '',
        cityInstallation: '',
        obchodniZastupceId: null,
        obchodniZastupceName: '',
        managerKoordinatorId: null,
        managerKoordinatorName: null,
        contactSource: null,
        noteContactSource: null,
        object: null,
        category: null,
        admPart: this.$store.getters.userLogin,
        intNumber: '',
        kraj: null,
        zaevidovanoDate: null,
        lastUpdatedAt: null,
        phone: null,
        email: null,
        verzeSoD: null,
        zip: null,
        okres: null,
      },
      lists: {
        obchodniZastupce: [],
        managerKoordinator: [],
        contactSource: [],
        object: [],
        category: [],
        kraj: [],
      },
      isKodZakazkyExist: false,
      isLoading: false,
      showLocal: false,
      isExistujiciObhodnyPripad: false, // with true enable 'Zkontrolovat existující obchodní případy'
      isValidatedExisting: false,
      formSaving: false,
    }
  },
  methods: {
    async getConstants() {
      try {
        const dataConst = await this.getConstantsFromServer(
            ['CONTACT_SOURCES', 'PRIPAD_CATEGORIES', 'KRAJ_SHORT']
        ) || {};
        this.lists.contactSource = dataConst.CONTACT_SOURCES;
        this.lists.object = PRIPAD_OBJECTS;
        this.lists.category = dataConst.PRIPAD_CATEGORIES;
        this.lists.kraj = dataConst.KRAJ_SHORT;
      } catch (e) {
        console.error('getConstants', e);
      }
    },

    async getObchodniZastupceList() {
      const url = `/user/getOzUsersGroupsForUser`;
      const {data} = await Axios.get(url, {});
      this.obchodniZastupce = data?.data?.activeOzUsersNamesFioTitles || [];
      this.obchodniZastupceFull = data?.data?.activeOzUsersInfo || [];
      this.obchodniZastupceFull.forEach(user => {
        this.obchodniZastupceObjById[user.id] = user.fullNameFioTitles;
      })
    },

    async getManagersKoordinators() {
      const url = `/user/getManagersKoordinators`;
      const {data} = await Axios.get(url, {});
      this.managerKoordinator = data?.data?.managersKoordinatorsNamesFioTitles || [];
      this.managerKoordinatorFull = data?.data?.managersKoordinatorsInfo || [];
      this.managerKoordinatorFull.forEach(user => {
        this.managerKoordinatorObjById[user.id] = user.fullNameFioTitles;
      })
    },

    async getSavedData() {
      this.lists.obchodniZastupce = this.obchodniZastupce;
      this.lists.managerKoordinator = this.managerKoordinator;

      if (!this.localId) {
        this.formData.obchodniZastupceName = this.getUserNumberOZ ? this.getUserFioTitles : '';
        this.formData.zaevidovanoDate = new Date();
        this.formData.object = OBJECT_TYPE.RD;
        this.formData.category = PRIPAD_TYPES.FVE;
        if (this.getNumberOz) {
          this.formData.kodZakazky = await this.getNextFreeZakazkaNumber(this.getNumberOz);
        } else {
          this.formData.kodZakazky = moment().format('YY');
        }
        return;
      }

      const url = `/obchod/getObchodniPripadInfo`;
      const { data: {data} } = await Axios.get(url, { params: { id: this.localId } });
      const info = data?.info || {};
      this.formData = {...this.formData, ...info};
      this.formData.obchodniZastupceName = _.get(this, `obchodniZastupceObjById.${info.obchodniZastupceId}`, '');
      this.formData.managerKoordinatorName = _.get(this, `managerKoordinatorObjById.${info.managerKoordinatorId}`, '');
      this.formData.lastUpdatedAt = moment(info.updatedAtObchod).isAfter(info.updatedAt) ? info.updatedAtObchod : info.updatedAt;
      if (info.obchodniZastupceId && !this.formData.obchodniZastupceName) {
        const {data: {data}} = await Axios.get(`/user/getUserInfo`, { params: { userId: info.obchodniZastupceId } });
        const user = data?.user || {};
        const userName = this.joinNameFioTitles(user) || '';
        this.formData.obchodniZastupceName = userName ? `bývalý OZ - ${userName}` : '';
      }
    },

    getUserIdByName() {
      const {obchodniZastupceName} = this.formData;
      const userByName = this.obchodniZastupceFull.find(user => obchodniZastupceName === user.fullNameFioTitles);
      return userByName?.id || null;
    },

    getManagerCoordinatorIdByName() {
      const {managerKoordinatorName} = this.formData;
      const userByName = this.managerKoordinatorFull.find(user => managerKoordinatorName === user.fullNameFioTitles);
      return userByName?.id || null;
    },

    showModalExistujiciObchodnyPripady() {
      this.$emit('show-existujici-obchodny-pripady-modal', this.formData);
    },

    async onSubmit() {
      try {
        if (this.isKodZakazkyExist) { return }

        this.isExistujiciObhodnyPripad = false;
        this.formSaving = true;
        const url = `/obchod/${this.localId ? 'updateObchodniPripad' : 'addNewObchodniPripad'}`;
        this.formData.obchodniZastupceId = this.getUserIdByName();
        this.formData.managerKoordinatorId = this.getManagerCoordinatorIdByName();
        const {data} = await Axios.post(url, { data: this.formData});
        this.$snotify[data.status](data.data.message);
        if (data.status === 'success') {
          this.$emit('form-saved', this.localId ? 'update' : 'add', this.formData.kodZakazky)
          this.$emit('close-modal');
        }
      } catch (e) {
        console.error('addContact', e.response?.data);
      }
    },

    async validateExistujiciObhodnyPripad() {
      if (this.localId || !this.isStartValidation) return;

      const urlValidateBySameObhodniPripad = `/obchod/existujiciObhodnyPripad`;
      const {data: {data}} = await Axios.post(urlValidateBySameObhodniPripad, { data: this.formData});
      this.isExistujiciObhodnyPripad = !!data?.isExistujiciObhodnyPripad;
      this.isNewObchodPripadFormCanBeSaved = false;
      this.isValidatedExisting = true;
    },

    validateWithDataFromList(key, isInput, listName, isCanBeEmpty = false) {
      if (isCanBeEmpty && !this.formData[key]) return false;
      const isEqual = this.lists[listName || key].includes(_.get(this.formData, key));
      return isInput && !isEqual ? false : !isEqual;
    },

    async validateKodZakazkyOnExist() {
      if (this.formData.kodZakazky.length < 8 || this.kodZakazky === this.formData.kodZakazky) {
        this.isKodZakazkyExist = false;
        return;
      }

      const url = `/obchod/checkExistKodZakazky`;
      const { data = {} } = await Axios.post(url, {kodZakazky: this.formData.kodZakazky, id: this.localId});
      this.isKodZakazkyExist = data.data.isExist;
    },

    getErrorKodPripadu(error) {
      return error === "Pole 'Kód případu' je povinné" ? '' : error;
    },

    async selectOzName() {
      if (this.localId) return;

      if (!this.formData.obchodniZastupceName) {
        this.formData.kodZakazky = moment().format('YY');
        return;
      }

      const userOzByName = this.obchodniZastupceFull.find(user => user.fullNameFioTitles === this.formData.obchodniZastupceName);
      let numberOZ = userOzByName?.numberOZ || '';
      if (numberOZ) {
        this.formData.kodZakazky = await this.getNextFreeZakazkaNumber(numberOZ);
        await this.changeObject(false);
        await this.changeCategory();
      }
      await this.validateKodZakazkyOnExist();
    },
    async getNextFreeZakazkaNumber(numberOZ) {
      const url = `/obchod/getNextFreeZakazkaNumber`;
      const {data} = await Axios.get(url, {params: {numberOZ}});
      return data?.data?.suggestedNumber || '';
    },
    changeKodZakazkySymbol8(symbol) {
      const str = this.formData.kodZakazky;
      if (str.length < 8) return;
      this.formData.kodZakazky = str.substring(0, 7) + symbol + str.substring(8);
    },
    changeKodZakazkySymbol9(symbol) {
      const str = this.formData.kodZakazky;
      if (str < 8) return;
      this.formData.kodZakazky = str.substring(0, 8) + symbol + str.substring(9);
    },
    async changeObject(isValidate = true) {
      switch (this.formData.object) {
        case OBJECT_TYPE.VS:
          this.changeKodZakazkySymbol8('S');
          break;
        case OBJECT_TYPE.RD:
          this.changeKodZakazkySymbol8('0');
          break;
        case OBJECT_TYPE.BD:
          this.changeKodZakazkySymbol8('B');
          break;
        case OBJECT_TYPE.FIRM:
          this.changeKodZakazkySymbol8('F');
          break;
      }
      if (!isValidate) return;
      await this.validateKodZakazkyOnExist();
    },
    async changeCategory() {
      switch (this.formData.category) {
        case PRIPAD_TYPES.FVE:
          this.changeKodZakazkySymbol9('');
          break;
        case PRIPAD_TYPES.OHREV_VODY:
          this.changeKodZakazkySymbol9('L');
          break;
        case PRIPAD_TYPES.ROZSIRENI:
          this.changeKodZakazkySymbol9('R');
          break;
        case PRIPAD_TYPES.VICEPRACE:
          this.changeKodZakazkySymbol9('V');
          break;
        case PRIPAD_TYPES.SVR:
          this.changeKodZakazkySymbol9('E');
          break;
        case PRIPAD_TYPES.TC:
          this.changeKodZakazkySymbol9('T');
          break;
        case PRIPAD_TYPES.NABIJECI_ELEKTROMOBILU:
          this.changeKodZakazkySymbol9('N');
          break;
        case PRIPAD_TYPES.SERVIS:
          this.changeKodZakazkySymbol9('X');
          break;
      }
      await this.validateKodZakazkyOnExist();
    },
    openPostaLink() {
      const zip = this.formData?.zip?.replace(' ', '');
      const mestoInstalace = this.formData?.cityInstallation;
      let searchString = zip ? `${zip}/` : '';
      if (!zip && mestoInstalace) {
        searchString = `?search=${mestoInstalace}&send=Odeslat&do=searchForm-submit`;
      }
      const url = `https://www.psc.cz/${searchString}`;
      window.open(url, '_blank', 'noopener,noreferrer');
    },
    openAresLink() {
      const addQuery = this.formData.clientLastName ? `?obchodniJmeno=${this.formData.clientLastName}` : '';
      window.open(`https://ares.gov.cz/ekonomicke-subjekty${addQuery}`, '_blank', 'noopener,noreferrer');
    },
    async changeZIP() {
      const psc = this.formData.zip.replace(' ', '');
      if (psc.length < 5) return this.formData.okres = null;

      await this.getOkresByPSC();
    },
    async getOkresByPSC() {
      try {
        const url = `/obchod/getOkresByPSC`;
        const psc = this.formData.zip.replace(' ', '');
        const {data: {data}} = await axios.get(url, {params: {psc}});
        this.formData.okres = data?.okres || '';
      } catch (e) {
        console.error('getOkresByPSC', e.response?.data);
      }
    },
    isTypeFirmOrVS(object) {
      return [OBJECT_TYPE.FIRM, OBJECT_TYPE.VS].includes(object);
    },
    closeCreateCopyOpModal() {
      this.$bvModal.hide('create-copy-op-modal');
    },
    openCreateOpModal() {
      this.$bvModal.show('create-copy-op-modal');
    },
    closeCopyOpDoneModal() {
      this.$bvModal.hide('copy-op-done-modal');
    },
    openCopyOpDoneModal() {
      this.$bvModal.show('copy-op-done-modal');
    },
    createCopyOP() {
      this.closeCreateCopyOpModal();
      this.openCopyOpDoneModal();
      this.localId = null;
      this.formData.category = PRIPAD_TYPES.FVE;
      this.formData.kodZakazky = this.formData.kodZakazky.substring(0, 8);
      this.formData.zaevidovanoDate = new Date();
      this.formData.lastUpdatedAt = null;
      this.formData.podepsana = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.localId = this.id;
    await this.getConstants();
    await this.getObchodniZastupceList();
    await this.getManagersKoordinators();
    await this.getSavedData();
    this.isLoading = false;
    this.showLocal = this.show;
  },
}
</script>

<style scoped>
.msg-notexist{
  color: #17a2b8;
  font-weight: bold;
}
.req {
  color: darkred;
  font-size: 17px
}
</style>